<template>
   <div class="container">
      <el-container class="wrap">
         <el-main class="mains">
            <div class="main_content">
               <div class="back m-b-20" @click="goBack()">
                  <img src="../assets/image/left.png" alt="">
                  返回
               </div>
               <div class="font-20 flex-x-between time m-b-20">
                  <span class="text-bold">本次考试用时:{{mockDetail.exam_time}}秒</span>
                  <span class="c-8d">于{{mockDetail.create_time}}进行的考试</span>
               </div>
               <div class="time analyze m-b-20 flex-x-between">
                  <div class="analyze_l">
                      <div class="head">
                        <div class="th1 font-16 text-bold">本次模考总分</div>
                        <div class="th2">
                           <span class="font-16 text-bold">单项分数</span>
                           <div class="font-16 text-bold flex-y-center" @click="getRecord()" style="cursor: pointer;">
                              查看答题记录
                              <img src="../assets/image/right.png" alt="" class="rightimg">
                           </div>
                        </div>
                      </div>
                      <div class="body flex">
                        <div class="num">{{mockDetail.check_score}}</div>
                        <div>
                           <div class="flex">
                              <div class="font-16 flex-x-between td td1">
                                 <span class="c-8d">Literacy</span>
                                 <span class="c-5d text-bold">{{mockDetail.Literacy}}</span>
                              </div>
                              <div class="font-16 flex-x-between td td2">
                                 <span class="c-8d">Comprehension</span>
                                 <span class="c-5d text-bold">{{mockDetail.Comprehension}}</span>
                              </div>
                           </div>
                           <div class="flex">
                              <div class="font-16 flex-x-between td td3">
                                 <span class="c-8d">Conversation</span>
                                 <span class="c-5d text-bold">{{mockDetail.Conversation}}</span>
                              </div>
                              <div class="font-16 flex-x-between td">
                                 <span class="c-8d">Production</span>
                                 <span class="c-5d text-bold">{{mockDetail.Production}}</span>
                              </div>
                           </div>
                        </div>
                      </div>
                  </div>
                  <div class="analyze_r">
                     <div>
                        <p class="name">{{mockDetail.mock_exam_paper_score_detail.name}}</p>
                        <div class="font-16 c-5d desc">
                           {{mockDetail.mock_exam_paper_score_detail.content}}
                        </div>
                     </div>
                     <img src="../assets/image/prize.png" alt="">
                  </div>
               </div>
               <!-- 小分分析 -->
               <div class="time m-b-20 smallScore flex-x-between">
                  <div class="small">
                     <div class="font-20 text-bold">小分分析</div>
                     <div id="Charts" ref="Echarts" class="chartBox">

                     </div>
                  </div>
                  <div class="mubiao">
                     {{mockDetail.small_score.message}}
                  </div>
               </div>
               <!-- 客观题考情分析 -->
               <div class="time m-b-20 keguan">
                  <div class="font-20 text-bold m-b-30">客观题考情分析</div>
                  <div class="progressBox">
                     <div class="item" v-for="(item,index) in impersonality_list" :key="index">
                        <el-progress type="circle" :percentage="item.percentage" stroke-width="15" color="#9166FF"></el-progress>
                        <div class="font-20 text-bold m-t-10">{{item.name}}</div>
                     </div>
                     
                  </div>
                  <div class="jie">
                     {{mockDetail.impersonality_ratio.message}}
                  </div>
               </div>
               <!-- 智能推荐-精准练习 -->
               <div class="time m-b-20 zhineng">
                  <div class="m-b-30">
                     <span class="font-20 text-bold m-r-20">智能推荐-精准练习</span>
                     <span class="font-16">{{mockDetail.smart_recommend.up_message}}</span>
                  </div>
                  
                  <div class="flex-x-between">
                     <el-table
                        :data="tableData"
                        border
                        style="width: 427px;margin-right:20px;">
                        <el-table-column
                           prop="name"
                           label="试题类型"
                           width="157">
                        </el-table-column>
                        <el-table-column
                           prop="total_count"
                           label="试题总数"
                           width="122">
                        </el-table-column>
                        <el-table-column
                           prop="recommend_count"
                           label="优先推荐刷题数">
                        </el-table-column>
                     </el-table>
                     <el-table
                        :data="tableData2"
                        border
                        style="width: 427px;margin-right:20px;">
                        <el-table-column
                           prop="name"
                           label="试题类型"
                           width="157">
                        </el-table-column>
                        <el-table-column
                           prop="total_count"
                           label="试题总数"
                           width="122">
                        </el-table-column>
                        <el-table-column
                           prop="recommend_count"
                           label="优先推荐刷题数">
                        </el-table-column>
                     </el-table>
                     <div class="jie">
                        {{mockDetail.smart_recommend.below_message}}
                     </div>
                  </div>
               </div>
            </div>
         </el-main>
    </el-container>
   </div>
</template>
<script>
export default {
   data() {
      return {
        tableData: [],
        tableData2: [],
        id: "",
        mockDetail: {},
        impersonality_list: []
      }
   },
   created() {
      this.id = this.$route.query.id;
      this.getMockDetail()
   },
   mounted() {
      //  this.$nextTick(()=>{
      //    this.initCharts()
      //  })
      setTimeout(() => {
         this.initCharts()
      }, 1000);
   },
   methods: {
      getRecord() {
         this.$router.push('/record?id='+this.id);
      },
      initCharts() {
         // 初始化echarts实例
         var myChart = this.$echarts.init(document.getElementById("Charts"))
         //取数据
         var zhu_name = [];
         var exam_arr = [];
         var target_arr = [];
         var temp_arr = this.mockDetail.small_score.list;
         temp_arr.forEach((v,k) => {
            zhu_name.push(v.name);
            exam_arr.push(v.exam_score)
            target_arr.push(v.target_score)
         })

         // 绘制图表
         myChart.setOption({
            tooltip: {},
            color: ["#9166FF","#D1BFFF"],
            xAxis: {
               data: zhu_name,
            },
            yAxis: {
               max: 140,
            },
            series: [
               {
                  name: "模考小分",
                  type: "bar",
                  barWidth: 28,
                  data: exam_arr,
               },
               {
                  name: "目标小分",
                  type: "bar",
                  barWidth: 28,
                  data: target_arr,
               }
            ],
         })
      },
      goBack() {
         this.$router.go(-1);
      },
      getMockDetail() {
         this.$api.getMockDetail({
            id: this.id
         }).then(res => {
            if(res.code == 1) {
               this.mockDetail = res.data;
               // 智能推荐数据拆分成两个表格
               if(res.data.smart_recommend.list.length>0) {
                  this.tableData = this.mockDetail.smart_recommend.list.slice(0,7);
                  this.tableData2 = this.mockDetail.smart_recommend.list.slice(7,14);
                  this.impersonality_list = res.data.impersonality_ratio.list;
                  this.impersonality_list.forEach((v,k) => {
                     var aa = v.ratio_name.split('%');
                     v.percentage = Number(aa[0]);
                  })
                  //整改柱状图数据
                  this.initCharts()
               }
            }
         })
      },
   }
}
</script>
<style  lang="scss" scoped>
.container,.wrap {
  width: 100%;
  height: 100vh;
  background: #F6F7FB;
}
.mains {
  height: calc(100% - 70px);
  padding-top: 62px;
  box-sizing: border-box;
}

.back {
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 14px;
   border-radius: 2px;
   background: #fff;
   width: 100px;
   height: 37px;
   cursor: pointer;
   img {
      width: 4px;
      height: 6px;
      margin-right: 10px;
   }
}

.time {
   border-radius: 2px;
   background: #fff;
   padding: 24px 30px;
}
.analyze {
   .analyze_l {
      width: 607px;
      border: 1px solid #DDDFE4;
      .head {
         display: flex;
         background: #F6F7FB;
         height: 60px;
         .th1 {
            width: 200px;
            padding-left: 20px;
            padding-top: 16px;
            box-sizing: border-box;
            border-right: 1px solid #DDDFE4;
            border-bottom: 1px solid #DDDFE4;
         }
         .th2 {
            display: flex;
            align-items: center;
            width: 407px;
            padding: 0 20px 0 20px;
            justify-content: space-between;
            border-bottom: 1px solid #DDDFE4;
            .rightimg {
               width: 4px;
               height: 7px;
               margin-left: 10px;
            }
         }
      }
      .body {
         .num {
            width: 187px;
            border-right: 1px solid #DDDFE4;
            box-sizing: border-box;
            font-size: 40px;
            color: $color-title2;
            padding-left: 20px;
            height: 135px;
            font-weight: 500;
            padding-top: 30px;
         }
         .td {
            display: flex;
            justify-content: space-between;
            width: 204px;
            height: 68px;
            line-height: 68px;
            padding: 0 20px;
            box-sizing: border-box;
         }
         .td1 {
            border-right: 1px solid #DDDFE4;
            border-bottom: 1px solid #DDDFE4;
         }
         .td2 {
            border-bottom: 1px solid #DDDFE4;
         }
         .td3 {
             border-right: 1px solid #DDDFE4;
         }
      }
   }
   .analyze_r {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 504px;
      border-radius: 2px;
      background: #F6F7FB;
      .name {
         width: 259px;
         height: 53px;
         line-height: 53px;
         text-align: center;
         background: #fff;
         border-radius: 2px;
         font-size: 20px;
         font-weight: bold;
         margin: 20px 0 14px 20px;
      }
      img {
         width: 194px;
         height: 194px;
      }
      .desc {
         width: 259px;
         margin-left: 20px;
      }
   }
}

.smallScore {
   .small {
      width: 600px;
      .chartBox {
         width: 600px;
         height: 300px;
      }
   }
   .mubiao {
      width: 456px;
      border-radius: 2px;
      background: #F6F7FB;
      padding: 20px;
   }
}
.keguan {
   .jie {
      border-radius: 2px;
      background: #F6F7FB;
      padding: 20px;
      font-size: 16px;
      color: #5D6581;
   }
   .progressBox {
      display: flex;
      flex-wrap: wrap;
      .item {
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         margin-bottom: 40px;
         width: 16%;
      }
   }
}
.zhineng {
   .jie {
      width: 236px;
      border-radius: 2px;
      background: #F6F7FB;
      padding: 20px;
      box-sizing: border-box;
      font-size: 16px;
      color: #5D6581;
   }
}
::v-deep th {
   background: #F6F7FB!important;
}
</style>
